import React from 'react'
import { graphql, Link } from "gatsby"
import Header from '../components/header'
import Footer from "../components/footer"
import SanitizeHTML from 'react-sanitized-html'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Project = (props) => {
    const project = props.data.allProjects.nodes[0];

    if(!project)
      return null;

      const parseUrl = function(val){
        const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;

        const parsed = val.match(vimeoRegex);

        return "//player.vimeo.com/video/" + parsed[1];
      }

      if(project.projectType === 'awards'){
      let imageCount = 1;

      return (
        <main style={{backgroundColor: '#fff'}}>
          <Header projectType={{title: 'Awards'}} projects={true} background={'#fff'} {...props} />

          <div className="main-content">
            <div className="container-fluid pos-rel">
              <div className="row">
                <div className="col-md-4">
                  <article>
                    <header className="d-md-none">
                      {/*{project.titleArtists && <h1 style={{margin: 0}} className="main">{project.titleArtists}</h1>}*/}
                      {/*<h1 className={"main"}>{project.title}</h1>*/}

                      <h1>{project.titleArtists ? <span>{project.titleArtists} <br /></span> : ''}{project.title}</h1>
                    </header>
                  </article>

                  <aside className="stick">
                    {project.images && project.images.map((image, key) => {
                      if(!image.sidebar)
                        return null

                      return (
                        <figure key={key}>
                          <AniLink direction="right" bg="#fff" to={'/award-gallery/' + project.slug + '?image=' + imageCount++}>
                            <picture><img src={image.sidebar} alt=""/></picture>
                          </AniLink>

                          <figcaption>{image.caption}</figcaption>
                        </figure>
                      )
                    })}
                  </aside>
                </div>

                <div className="col-md-8">
                  <article className="stick">
                    <header className="d-none d-md-block">
                      {project.titleArtists && <h1 style={{margin: 0}} className="main">{project.titleArtists}</h1>}
                      <h1 className={"main"}>{project.title}</h1>
                    </header>

                    <SanitizeHTML html={project.content}/>

                    {project.sponsorsText &&
                      <footer>
                        <SanitizeHTML html={project.sponsorsText}/>
                      </footer>
                    }
                  </article>
                </div>
              </div>
            </div>
          </div>

          <Footer projectType={project.projectType} articleType={'project'} {...props} article={project} />
        </main>
      )
    }

    return (
      <main style={{backgroundColor: '#ECECEC'}}>
        <Header projectType={props.data.projectType.nodes[0]} background={'#ECECEC'} {...props} />

        <section className="main-content">
          <div className="container-fluid single-project pos-rel">
            {project.images &&
              <div className="row">
                <div className="col-lg-8">
                  <aside className="stick">
                    {project.images && project.images.map((image, key) => {
                      if(!image.gallery)
                        return null;

                      return (
                        <figure key={key}>
                          <picture className={"project-image no-hover"} >
                            <img alt={''} key={key} src={image.gallery}/>
                          </picture>

                          {image.caption && <figcaption>{image.caption}</figcaption>}
                        </figure>
                      )
                    })}

                    {!!project.vimeo_url &&
                      <div className='embed-container'><iframe src={parseUrl(project.vimeo_url)} frameBorder='0' allowFullScreen /></div>
                    }
                  </aside>
                </div>

                <div className="col-lg-4">
                  <article className="stick">
                    {/*{project.titleArtists && <h1 style={{margin: 0}} className="main">{project.titleArtists}</h1>}*/}
                    {/*<h1 className={"main"}>{project.title}</h1>*/}
                    <h1 className={"main"}>{project.titleArtists ? <span>{project.titleArtists} <br /></span> : ''}{project.title}</h1>

                    <SanitizeHTML html={project.content}/>

                    {project.sponsorsText &&
                      <footer>
                        <SanitizeHTML html={project.sponsorsText}/>
                      </footer>
                    }
                  </article>
                </div>
              </div>
            }

            {!project.images &&
              <div className="row">
                <div className="col-lg-8">
                  <h1 className={"main"}>{project.title}</h1>

                  <SanitizeHTML html={project.content}/>
                </div>
              </div>
            }

          </div>
        </section>

        <Footer projectType={project.projectType} articleType={'project'} {...props} article={project} />
      </main>
    )
}

export const query = graphql`
  query($slug: String) {
    projectType: allFullprojecttypes(filter: {projects: {elemMatch: {slug: {eq: $slug}}}}) {
      nodes {
        subType
        title
        slug
      }
    }
    allProjects(filter: {slug: {eq: $slug}}) {
      nodes {
        title
        content,
        titleArtists,
        slug,
        projectType
        nextArticle {
          title
          slug
        }
        previousArticle {
          title
          slug
        }
        vimeo_url,
        images {
          gallery
          caption,
          sidebar
        }
        sponsorsText
      }
    }
  }
`

export default Project