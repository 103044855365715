import React, {useState, useEffect} from 'react'
import Project from './project'
import Article from './article'
import queryString from 'query-string';

const Preview = (props) => {
  const parsed = queryString.parse(props.location.search);
  const {
    preview_id,
    post_type
  } = parsed;

    const [project, setProject] = useState({
      allProjects: {
        nodes: [
          {}
        ]
      }
    });

    const [article, setArticle] = useState({
      allArticles: {
        edges: [
          {
            node: {}
          }
        ]
      }
    });

  useEffect(() => {

    if(preview_id) {
      fetch('https://cms.nextleveluk.com/wp-json/wp/v2/'+post_type+'/' + preview_id)
        .then(response => response.json())
        .then(resultData => {

          if(post_type === 'project') {
            setProject({
              allProjects: {
                nodes: [
                  {
                    title: resultData.title.rendered,
                    content: '<p>Content will go here once the API is hooked up fully</p>'
                  }
                ]
              }
            });
          }
          else {
            setArticle({
              allArticles: {
                edges: [
                  {
                    node: {
                      title: resultData.title.rendered,
                      content: {
                        'English': '<p>Content will go here once the API is hooked up fully</p>'
                      }
                    }
                  }
                ]
              }
            });
          }
        })
    }
  }, [])

  if(post_type === 'project'){
    return <Project data={project} />
  }

  if(post_type === 'article' && preview_id){
    return <Article data={article} />
  }

  return null
}

export default Preview