import React, {useState, useEffect} from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { graphql } from 'gatsby'
import Header from '../components/header'
import Img from "gatsby-image"
import Footer from '../components/footer'
import SanitizeHTML from 'react-sanitized-html'

const Article = (props) => {
    const [state, setState] = useState({
      currentLanguage: 'English',
      loaded: false
    });

    const setLanguage = (language) => {
      setState({
        currentLanguage: language
      })
    }

    useEffect(() => {
      if(typeof props.data.allArticles.edges[0].node.images !== 'undefined') {
        props.data.allArticles.edges[0].node.images.forEach((picture) => {
          if (picture.gallery && picture.gallery.url) {
            const img = new Image();
            img.src = picture.gallery.url;
          }
        });
      }
    })

    if(!props.data.allArticles.edges)
      return null;

    const article = props.data.allArticles.edges[0].node;
    let imageCount = 1;

    let heroCount = 0;
    article.images.forEach((item, key) => {

      if(item.hero){
        heroCount = key;
      }
    })

    return (
      <main style={{backgroundColor: '#fff'}}>
        <Header {...props} nextArticle={article.nextArticle} previousArticle={article.previousArticle} magazine={typeof props.data.allMagazines !== 'undefined' ? props.data.allMagazines.nodes[0] : null} />

        <div className="main-content">
          <div className="container-fluid pos-rel">
            <div className="row">
              <div className="col-md-4">
                {article.magazine.coverImage &&
                  <figure className="cover d-none d-md-block">
                    <AniLink cover direction={"right"} bg={article.magazine.coverImageMainColour} to={"/magazine/" + article.magazine.slug}>
                      <picture><img alt={''} src={article.magazine.coverImage}/></picture>

                      <div style={{backgroundColor: article.magazine.coverImageMainColour}} className="cover">
                        <p>Contents page</p>
                      </div>
                    </AniLink>
                  </figure>
                }

                <article>
                  <header className="d-md-none">
                    <h1>{article.titleArtists ? <SanitizeHTML  allowedTags={['span']} html={article.titleArtists}/> : ''}{article.title}</h1>

                    {article.subTitle && <h2>{article.subTitle}</h2>}
                  </header>
                </article>

                <aside className="stick">
                  {article.images && article.images.map((image, key) => {
                    if(!image.sidebar)
                      return null

                    return (
                      <figure key={key}>
                        <AniLink className={'d-none d-md-block'} direction="right" bg="#fff" to={'/gallery/' + article.slug + '?image=' + imageCount++}>
                          <picture><img src={image.sidebar} alt=""/></picture>
                        </AniLink>

                        <picture className={'d-md-none'}><img src={image.sidebar} alt=""/></picture>

                        <figcaption>{image.caption}</figcaption>
                      </figure>
                    )
                  })}

                  {article.quotes && article.quotes.map((quote, key) => {
                    return (
                      <blockquote>
                        &lsquo;{quote.text}&rsquo;

                        <cite>{quote.author}</cite>
                      </blockquote>
                    )
                  })}
                </aside>
              </div>

              <div className="col-md-8">
                <article className="stick">
                  <header className="d-none d-md-block">
                    <h1>{article.titleArtists ? <SanitizeHTML  allowedTags={['span']} html={article.titleArtists}/> : ''}{article.title}</h1>

                    {article.subTitle && <h2>{article.subTitle}</h2>}
                  </header>

                  {article.heroImage && article.heroImage.sizes.large &&
                    <figure className={"hero"}>
                      <AniLink direction="right" bg="#fff" to={'/gallery/' + article.slug + '?image=' + (heroCount+1)}>
                        <picture><img src={article.heroImage.sizes.large} alt=""/></picture>
                      </AniLink>

                      {article.heroImage.caption && <figcaption>{article.heroImage.caption}</figcaption>}
                    </figure>
                  }

                  {typeof article.languages !== 'undefined' && article.languages.length > 0 &&
                  <nav>
                    <p>
                      {article.languages.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            <button onClick={() => setLanguage(item)}
                                    className={state.currentLanguage === item ? 'active' : ''}>{item}</button>

                            <span>/</span>
                          </React.Fragment>
                        )
                      })}
                    </p>
                  </nav>
                  }

                  {article.content.map((item, key) => {
                    if(state.currentLanguage === item.lang) {
                      return (
                        <SanitizeHTML key={key} html={item.content}/>
                      )
                    }
                  })}

                  <footer>
                    {typeof article.artists !== 'undefined' && article.artists && article.artists.length > 0 && article.artists.map((artist, key) => {
                      return <p key={key}>
                        <strong>{artist.artistType}: {artist.title}</strong> {artist.biography.map((item, key) => {
                          if(item.lang === state.currentLanguage)
                          return item.content
                      })}
                      </p>
                    })}
                  </footer>
                </article>
              </div>
            </div>
          </div>
        </div>

        <Footer {...props} article={article} />
      </main>
    )
}

export const query = graphql`
  query($slug: String) {
    allMagazines(filter: {articles: {elemMatch: {slug: {eq: $slug}}}}) {
      nodes {
        title,
        slug,
        magazineNumber
      }
    }
    allArticles(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          id
          languages
          magazine {
            slug,
            coverImage
            coverImageMainColour
          }
          artists {
            biography {
              lang
              content
            }
            title
            artistType
          }
          title
          titleArtists
          subTitle
          slug
          quotes {
            author
            text
          }
          content {
            lang
            content
          }
          nextArticle {
            title
            slug
          }
          previousArticle {
            title
            slug
          }
          heroImage {
            sizes {
              large
            }
            caption
          }
          images {
            gallery
            caption
            hero
            sidebar
          }
        }
      }
    }
  }
`

export default Article